import React from 'react'
import './css/homePage.scss'
import {Link} from "react-scroll";
import {textSpliter} from './js/textSpliter.js'
const Homepage = () => {
  const mainPageText = `
I am a second-year student pursuing a degree in game development, with a strong foundation in full-stack web development and a passion for coding, software, and game creation. 
<br>
Outside of academics, I enjoy learning to play the guitar, roller skating, and staying active through gym calisthenics and volleyball. 
<br>
I am also an enthusiast of D&D-style board games and PC RPGs, including Gothic, Witcher, Divinity, Neverwinter Nights, and Baldur's Gate.
<br>
I am driven to apply my skills in real-world projects and collaborate with like-minded individuals to create impactful and engaging experiences.
<br>

 `;
 let splitedText = textSpliter(mainPageText, '\n');

  return (
    <div id="homePage" className="homePage " style={{backgroundImage: 'url("./img/bg.jpg")',}}>
      <div className='mainPageAboutWrapper'>
        <h1 className='mainPageHeader'>JUNIOR GAME DEVELOPER</h1>
        <div className='aboutText'>
          {splitedText}
        </div>
        <Link to='skills' spy={true} hashSpy={true} smooth={true} offset={-50} duration={500}> 
          <button className='mainPageSkillBtn'>SKILLS</button>
        </Link>
      </div>
    </div>
  )
}
export {Homepage}