import React, { useEffect, useState } from "react";
import "./css/work.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBriefcase, faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import {workEduAnim} from "./js/animation.js";
import { textSpliter, linkSpliter } from "./js/textSpliter.js";

const briefcase = <FontAwesomeIcon icon={faBriefcase} />;
const CaretDown = <FontAwesomeIcon className="carretDownWork" icon={faCaretDown} />;
const CaretUp = <FontAwesomeIcon className="carretDownWork" icon={faCaretUp} />;

const Work = () => {


  const [count, setCount] = useState([]);
  const [collapsable, setCollapsable] = useState([]);

  useEffect(() => {
    const links = [];
    links[0] = "https://leogurockin.info/backend/work2.php";
    links[1] = "http://localhost/backend/work2.php";
    fetch(links[0])
      .then((response) => response.json())
      .then((data) => {
        setCount(data.reverse());
        setCollapsable(
          data.map((work) => (work.collapsable === 0 ? false : true)) // Initialize based on `work.collapsable`
        );
      });
  }, []);

  useEffect(() => { 
    workEduAnim(".invisibleWork", "visible");
  },[count] );

 

  const onToggleCollapsable = (index) => 
    {
      setCollapsable((prevStates) =>
        prevStates.map((state, idx) => (idx === index ? !state : state))
    );
  };

  return (
    <div id="work" className="workExperience">
      <div className="timeline">
        <h2 className="workPageHeader">
          <img src="./img/work-expirience-icon.png" alt="" height={40}  width={40}  />
          <span className='sectionHeaders'>  EXPERIENCE   </span>
        </h2>
        {count.map((work, index) => {
          let splitedText = textSpliter(work.Body, "\n");
          let links = linkSpliter(work.link, work.linkName, "\n");
          return (
            <div className="container right " key={index}>
              <div className="content invisibleWork" >
                <h4 className="workRowHeader"  onClick={() => onToggleCollapsable(index)}>
                  {briefcase}{" "}
                  {work.RowName}
                  {collapsable[index] ? CaretDown : CaretUp }
                </h4>
                <div
                  className=
                  {
                      collapsable[index]
                      ? "hideWork"
                      : ""
                  }
                >
                  {splitedText}
                  {links}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export { Work };
