import React, { useEffect, useState } from "react";
import "./css/education.scss";
import {workEduAnim} from "./js/animation.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGraduationCap, faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { textSpliter, linkSpliter } from "./js/textSpliter.js";

const Education = () => {
  const [count, setCount] = useState([]);
  const [collapsable, setCollapsable] = useState([]);



  useEffect(() => {
    const links = [];
    links[0] = "https://leogurockin.info/backend/work.php";
    links[1] = "http://localhost/backend/work.php";
    fetch(links[0])
      .then((response) => response.json())
      .then((data) => {
        setCount(data.reverse());
        setCollapsable(data.map(() => false)); // Initialize all sections as collapsed
      });
  }, []);

  useEffect(() => { 
    workEduAnim(".invisibleEducation", "visible");
  },[count]  );

  const onToggleCollapsable = (index) => {
    setCollapsable((prevStates) =>
      prevStates.map((state, idx) => (idx === index ? !state : state))
    );
  };

  return (
    <div id="education" className="home">
      <div className="timeline educationWrapper">
        <h2 className="educationPageHeader">
          <img src="./img/education-icon.png" alt="" height={40} width={40} />
          <span className='sectionHeaders'> EDUCATION </span>
        </h2>
        {count.map((education, index) => {
          let links = linkSpliter(education.link, education.linkName, "\n");
          let splitedText = textSpliter(education.Body, "\n");

          return (
            <div className="container right testwrapper" key={index}>
              <div className="content invisibleEducation">
                <h4 className="educationListHeader" onClick={() => onToggleCollapsable(index)}>
                  <FontAwesomeIcon icon={faGraduationCap} /> {education.RowName}
                  <FontAwesomeIcon
                    icon={collapsable[index] ? faCaretUp : faCaretDown}
                    className="caretIcon"
                  />
                </h4>
                <div className={collapsable[index] ? "" : "hideWork"}>
                  {splitedText}
                  {links}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export { Education };
