export function textSpliter(text, spliter) {
  // Split the text by the main delimiter
  let splitedText = text.split(spliter);

  // Map through the array of split texts
  let newText = splitedText.map((i, index) => {
    // Split further if there's a <br> tag inside the text
    const parts = i.split("<br>");
    
    // If there's a <br>, we need to render it separately with extra margin
    const renderedParts = parts.map((part, partIndex) => (
      <article className="newLineArticle" key={`${index}-${partIndex}`}>
        {part}
      </article>
    ));

    // Return the rendered parts
    return (
      <div key={index} className="textBlock">
        {renderedParts}
        {/* Add margin if this is a <br> split */}
        {parts.length > 1 && <div className="extraMargin"></div>}
      </div>
    );
  });

  return newText;
}

export  function linkSpliter(link,linkName, spliter) {
  let splitedLinks = link.split(spliter);
  let splitedLinkName = linkName.split(spliter);
  let newLinks = splitedLinks.map((link, index) => {
    return ( 
      <div key={index}> 
        <a className='linkStyle ' href={link} target={'_blank'} rel="noreferrer" >
          {splitedLinkName[index]} 
        </a>
      </div>   
    )
  })
  return newLinks;    
}
 