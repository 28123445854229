const workEduAnim = (entryTarget, addClass ) => {
  const observer = new IntersectionObserver((entries, observer) => 
  {
    entries.forEach(entry => 
    {
      if (entry.isIntersecting) 
      {
        entry.target.classList.remove(entryTarget);
        entry.target.classList.add(addClass);
        observer.unobserve(entry.target);
      }
    });
  }, {
    threshold: 0.5 // Trigger when 50% of the element is visible in the viewport
  });

  // Observe all elements matching the container

  document.querySelectorAll(entryTarget).forEach(element => {
    
    observer.observe(element);
  });
}
 

const skillsAnim = (entryTarget, toggleClass, container) => {
  const observer = new IntersectionObserver((entries, observer) => {
    entries.forEach(entry => {
      const square2 = entry.target.querySelector(entryTarget);

      // Check if the element is in the viewport
      if (entry.isIntersecting) {
        square2.classList.remove(toggleClass);  // Add your animation class or remove the hide class

        // Once the element is observed, disconnect the observer to prevent further triggers
        observer.unobserve(entry.target);
      }
    });
  });

  // Observe all elements matching the container
  document.querySelectorAll(container).forEach(element => {
    
    observer.observe(element);
  });
}


export  {workEduAnim, skillsAnim};