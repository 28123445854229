import './css/logoArea.scss'

function LogoArea() {
  return (
    <div className="LogoArea">
      <div className='logoImg'  style={{backgroundImage: 'url("./img/logo.jpg")'}}></div>
        <p className='personalName'>Leonids Gurockins</p>
    </div>
  )
}

export default LogoArea